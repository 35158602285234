<template>
  <div>
    <h1>test</h1>
    <h1>{{ message }}</h1>
    <img src="../assets/logo.png" />
    <img src="../assets/okkk.jpg" />
    <img src="../assets/uc.webp" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: ""
    };
  },
  mounted() {
    // Fetch data from your .NET Core API
    fetch('/api/hello')
      .then(response => response.json())
      .then(data => {
        this.message = data.message;
      })
      .catch(error => {
        console.error('Error fetching API:', error);
      });
  }
}
</script>

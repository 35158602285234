<template>
    <header>
      <h1>Find Cool Spots!</h1>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderComponent',
  };
  </script>
  
  <style scoped>
  header {
    background-color: #333;
    color: white;
    padding: 5px;
    text-align: center;
  }
  </style>
  
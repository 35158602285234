<template>
  <div id="app">
    <HeaderComponent />

    <div class="main-content">
      <!-- <NavComponent /> -->
      <div id="map" style="width: 100%; height: 500px; position: relative;">
        <!-- Search box for location -->
        <div class="search-box">
          <input type="text" v-model="searchQuery" placeholder="Search location..." @input="debouncedGetSuggestions"
            @keydown="handleKeydown" @compositionend="debouncedGetSuggestions" @blur="handleBlur" />
          <ul v-if="suggestions.length > 0" class="suggestions">
            <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
              {{ suggestion.place_name }}
            </li>
          </ul>
        </div>
        <!-- Button to place a new marker -->
        <div class="place-marker-button" @click="placeMarker" v-if="!showModal">
          🛑 Place Marker
        </div>
        <div class="location-button" @click="goToUserLocation">
          📍 My Location
        </div>
      </div>
    </div>
    <FooterComponent />

    <!-- Modal for adding/editing a marker name, time, and notes -->
    <div v-if="showModal" :class="isMobile ? 'modal mobile-modal' : 'modal-overlay'">
      <div class="modal">
        <h3>Add/Edit Marker Details {{ isMobile }}</h3>
        <input v-model="markerName" placeholder="Marker Name" />
        <textarea v-model="markerNotes" placeholder="Enter notes here..."></textarea>
        <p>Created At: {{ new Date().toLocaleString() }} </p>
        <button @click="saveMarker">Save Marker</button>
        <button @click="cancelMarker">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';
// import NavComponent from './NavComponent.vue';
import debounce from 'lodash/debounce';

export default {
  name: 'MapComponent',
  components: {
    HeaderComponent,
    FooterComponent
    // NavComponent,
  },
  setup() {
    const map = ref(null);
    const userLocation = ref([0, 0]);
    const marker = ref(null); // Will hold the currently placed marker
    const savedMarker = ref(null); // For editing saved markers
    const showModal = ref(false);
    const markerName = ref('');
    const markerNotes = ref('');
    const markerTime = ref('');
    const searchQuery = ref(''); // For search
    const suggestions = ref([]); // To hold search suggestions
    const isMobile = computed(() => window.innerWidth <= 768); // Check if the device is mobile


    // Clear marker form data
    const clearMarkerForm = () => {
      markerName.value = '';
      markerNotes.value = '';
      markerTime.value = '';
    };

    // Get user's current location and center map
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            userLocation.value = [position.coords.longitude, position.coords.latitude];
            map.value.setCenter(userLocation.value);
          },
          () => {
            console.log('Unable to retrieve location');
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    // Fetch saved markers from API and add them to the map
    const loadMarkers = async () => {
      try {
        const response = await fetch('/api/markers');
        const markers = await response.json();
        markers.forEach(markerData => {
          const newMarker = new mapboxgl.Marker({ color: 'blue' })
            .setLngLat([markerData.longitude, markerData.latitude])
            .addTo(map.value);

          // Add click listener to open modal for editing
          newMarker.getElement().addEventListener('click', () => {
            const markerDate = new Date(markerData.createdAt);
            markerName.value = markerData.name;
            markerNotes.value = markerData.notes;
            markerTime.value = markerDate.toLocaleString();
            savedMarker.value = newMarker; // Keep reference to the clicked marker
            showModal.value = true;
          });
        });
      } catch (error) {
        console.error('Error fetching markers:', error);
      }
    };

    // Place marker in the center of the map
    const placeMarker = () => {
      if (showModal.value === true) {
        return;
      }
      const center = map.value.getCenter();
      marker.value = new mapboxgl.Marker({ color: 'red', draggable: true })
        .setLngLat(center)
        .addTo(map.value);

      markerTime.value = new Date().toLocaleString(); // Capture the time of marker placement
      clearMarkerForm(); // Clear form data
      showModal.value = true; // Show modal for marker details
    };

    // Save marker details (name, time, and notes)
    const saveMarker = async () => {
      const lngLat = marker.value ? marker.value.getLngLat() : savedMarker.value.getLngLat();
      try {
        const response = await fetch('/api/markers', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: markerName.value,
            notes: markerNotes.value,
            longitude: lngLat.lng,
            latitude: lngLat.lat,
            time: new Date().toISOString(), // Capture the current time in ISO format
          }),
        });
        if (response.ok) {
          alert('Marker saved successfully!');
          showModal.value = false;
          loadMarkers(); // Reload markers after saving
          marker.value = null; // Reset marker reference after saving
        }
      } catch (error) {
        console.error('Error saving marker:', error);
      }
    };

    // Cancel marker creation and remove the marker from the map
    const cancelMarker = () => {
      if (marker.value) {
        marker.value.remove(); // Remove unsaved marker
        marker.value = null;
      }
      clearMarkerForm(); // Clear form data
      showModal.value = false;
    };

    // Updated handleKeydown function
    const handleKeydown = (event) => {
      if (isMobile.value || event.key === "Enter" || event.key === " ") {
        getSuggestions();
      }
    };

    const debouncedGetSuggestions = debounce(() => {
  getSuggestions();
}, 200);

    // Updated getSuggestions with debounce for smoother performance
    const getSuggestions = async () => {
      if (!searchQuery.value.trim()) {
        suggestions.value = [];
        return;
      }
      const searchUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        searchQuery.value
      )}.json?access_token=${mapboxgl.accessToken}&autocomplete=true`;
      try {
        const response = await fetch(searchUrl);
        const data = await response.json();
        suggestions.value = data.features;
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };


    // Select a suggestion and fly to its location
    const selectSuggestion = (suggestion) => {
      const [lng, lat] = suggestion.center;
      map.value.flyTo({ center: [lng, lat], zoom: 14 });
      suggestions.value = []; // Clear suggestions after selection
    };

    // Clear suggestions when clicking away from the search box
    const handleBlur = () => {
      setTimeout(() => {
        suggestions.value = [];
        searchQuery.value = ''; // Clear search query when clicked away
      }, 300); // Timeout to ensure that clicks on suggestions are not interrupted
    };




    const handleLongPress = () => {
      let pressTimer;
      let isSingleFinger = false;
      let isDragging = false;

      // Update `isDragging` based on drag events
      map.value.on('dragstart', () => {
        isDragging = true;
      });

      map.value.on('dragend', () => {
        isDragging = false;
      });

      map.value.on('touchstart', (e) => {
        if (showModal.value || isDragging) {
          return; // Prevent marker placement if modal is open or map is moving
        }

        if (e.originalEvent.touches.length === 1) { // Single touch detection
          isSingleFinger = true;
          pressTimer = setTimeout(() => {
            if (isSingleFinger && !isDragging) { // Only add marker if map is not moving
              marker.value = new mapboxgl.Marker({ color: 'red', draggable: true })
                .setLngLat([e.lngLat.lng, e.lngLat.lat])
                .addTo(map.value);
              markerTime.value = new Date().toLocaleString();
              clearMarkerForm(); // Clear form data
              showModal.value = true;
            }
          }, 700); // Long press duration
        }
      });

      map.value.on('touchmove', (e) => {
        if (e.originalEvent.touches.length > 1) { // Cancel if multiple touches are detected
          isSingleFinger = false;
          clearTimeout(pressTimer);
        }
      });

      map.value.on('touchend', () => {
        clearTimeout(pressTimer);
        isSingleFinger = false;
      });
    };

    // Initialize map
    onMounted(() => {
      mapboxgl.accessToken = 'pk.eyJ1Ijoia2V2bG9jYnVybiIsImEiOiJjbTI5amFrcjkwNXQzMmluMXV2YjdqazB5In0.UvQAuwhY2EmN_Cyp28aBbg';

      map.value = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-70.81705103522981, 42.89220803880476], // Default center
        zoom: 14,
      });

      getUserLocation(); // Center on user's location
      loadMarkers(); // Load previously saved markers

      if (isMobile.value) {
        handleLongPress(); // Enable long press for mobile
      }
    });

    const goToUserLocation = () => {
      if (userLocation.value) {
        map.value.flyTo({ center: userLocation.value, zoom: 14 });
      } else {
        getUserLocation(); // If location isn't set, retrieve it
      }
    };


    return {
      map,
      showModal,
      goToUserLocation,
      markerName,
      markerNotes,
      markerTime,
      searchQuery,
      suggestions,
      placeMarker,
      saveMarker,
      cancelMarker,
      getSuggestions,
      selectSuggestion,
      handleBlur,
      isMobile,
      handleKeydown,
      debouncedGetSuggestions,
    };
  },
};
</script>

<style scoped>
.main-content {
  display: flex;
}

#map {
  flex-grow: 1;
  height: 500px;
  position: relative;
}

.location-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.place-marker-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.search-box {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5%;
  padding: 10px;
  z-index: 10;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 11;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 280px;
}

.modal.mobile-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px 15px 0 0;
  z-index: 9999;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
  background: white;
  border-bottom: 1px solid #ddd;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}
</style>

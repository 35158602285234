<template>
    <footer>
    <p>&copy; {{ new Date().getFullYear() }}</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
  footer {
    background-color: #333;
    color: white;
    padding: 5px;
    text-align: center;
  }
  </style>
  
// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import MapComponent from './components/MapComponent.vue';
import HelloWorldComponent from './components/HelloWorld.vue';

const routes = [
  {
    path: '/',         // Homepage route
    name: 'Home',
    component: MapComponent
  },
  {
    path: '/hello',    // Hello World route
    name: 'HelloWorld',
    component: HelloWorldComponent
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

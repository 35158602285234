<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view> <!-- This renders the component based on the current route -->
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Global styles can go here */
</style>
